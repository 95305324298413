import MapTypes from '../action-types/map';

const internals = {
    initial: () => ({
        selectedJurisdiction: null,
        // user data
        mapList: {
            complete: false,
            error: false,
            errorMsg: '',
            data: null,
        },
        mapOrgList: {
            complete: false,
            error: false,
            errorMsg: '',
            data: null,
        },
        activeEditingMap: {
            complete: false,
            error: false,
            errorMsg: '',
            data: {
                spreadsheet: null,
                title: null,
                description: null,
                passcodeRequired: false,
                legend: null,
                legendTitle: null,
                id: null,
                passcode: null,
            },
        },
        // client data
        loadedMap: {
            complete: false,
            error: false,
            errorMsg: '',
            data: null,
        },
        jurisdictionPDFDownloading: false,
    }),
};

const MapReducer = (stateParam, action) => {
    const state = stateParam || internals.initial();

    const { payload, type } = action;

    switch (type) {
    case MapTypes.DOWNLOAD_JURISDICTION_PDF_BEGIN:
        return {
            ...state,
            jurisdictionPDFDownloading: true,
        };
    case MapTypes.DOWNLOAD_JURISDICTION_PDF_ERROR:
    case MapTypes.DOWNLOAD_JURISDICTION_PDF_SUCCESS:
        return {
            ...state,
            jurisdictionPDFDownloading: false,
        };
    case MapTypes.UPDATE_SELECTED_JURISDICTION:
        return {
            ...state,
            selectedJurisdiction: payload,
        };

    case MapTypes.LOAD_MAP_BEGIN:
    case MapTypes.LOAD_MAP_ERROR:
    case MapTypes.LOAD_MAP_SUCCESS:
        return {
            ...state,
            loadedMap: payload,
        };

    case MapTypes.SAVE_MAP_BEGIN:
    case MapTypes.SAVE_MAP_ERROR:
    case MapTypes.SAVE_MAP_SUCCESS:
    case MapTypes.UPDATE_MAP_BEGIN:
    case MapTypes.UPDATE_MAP_ERROR:
    case MapTypes.UPDATE_MAP_SUCCESS:
        return {
            ...state,
            activeEditingMap: { ...state.activeEditingMap, ...payload },
        };

    case MapTypes.RESET_ACTIVE_MAP:
        return {
            ...state,
            activeEditingMap: {
                ...state.activeEditingMap,
                data: {
                    spreadsheet: null,
                    title: null,
                    description: null,
                    passcodeRequired: false,
                    legend: null,
                    id: null,
                    passcode: null,
                },
            },
        };

    case MapTypes.LOAD_MAP_LIST_BEGIN:
    case MapTypes.LOAD_MAP_LIST_ERROR:
    case MapTypes.LOAD_MAP_LIST_SUCCESS:
        return {
            ...state,
            mapList: { ...state.mapList, ...payload },
        };

    case MapTypes.LOAD_ORG_MAP_LIST_BEGIN:
    case MapTypes.LOAD_ORG_MAP_LIST_ERROR:
    case MapTypes.LOAD_ORG_MAP_LIST_SUCCESS:
        return {
            ...state,
            mapOrgList: { ...state.mapOrgList, ...payload },
        };

    default:
        // do nothing
    }
    return state;
};

export default MapReducer;
